import 'selectize';

import axios from 'axios';
import $ from 'jquery';
import { defaults, get, has, isUndefined } from 'lodash';
import Vue from 'vue';

export const bus = new Vue();

export const http = axios.create({
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export const el = (selector, source = document) =>
  source.querySelector(selector);

export const els = (selector, source = document) => [
  ...source.querySelectorAll(selector),
];

/**
 * Return an array of values to select on the element based on the data-selected
 * attribute value.
 */
const selectedFor = element => {
  const source = element.dataset.selected;

  try {
    const parsed = JSON.parse(element.dataset.selected);

    if (parsed.toString() != source) {
      return parsed;
    }
  } catch (e) {
    //
  }

  // always return an array
  return [source].reduce((a, b) => a.concat(b), []);
};

/**
 * Initialize the selectize plugin, marking options with values matching entries
 * in the data-selected attribute on the elements matching the selector.
 *
 * For a multi-select, the 'remove_button' plugin is automatically
 */
export const selectize = (selector = '.selectize', options = {}) => {
  const selects = els(selector);

  [].forEach.call(selects, select => {
    const opts = defaults(options, { plugins: [] });

    if (select.hasAttribute('multiple')) {
      opts.plugins.push('remove_button');
    }

    opts.items = selectedFor(select);

    $(select).selectize(opts);
  });
};

//
// Mark options with values matching entries in the data-selected attribute on
// the elements matching the selector.
//
export const select = selector => {
  const selects = els(selector);

  [].forEach.call(selects, select => {
    selectedFor(select).forEach(v => {
      if (v === '') {
        return;
      }

      const option = el('option[value="' + v + '"]', select);

      if (!option) {
        return;
      }

      option.selected = true;
    });
  });
};

export const token = () => Math.random().toString(36).substr(2);

export const slug = str =>
  str
    .replace(/[^0-9a-z]+/gi, '-')
    .trim('-')
    .toLowerCase();

export const fail = message => {
  throw new Error(message);
};

export const fetchInjection = (path, fallback) => {
  if (has(WFC, path)) {
    return get(WFC, path, fallback);
  }

  if (isUndefined(fallback)) {
    return fallback;
  }

  fail(
    `No [${path}] property could be found on the [WFC] global object, and no fallback was provided.`
  );
};

/**
 * Convert a value that may be a float like 3.23 or cents like 323 into
 */
export const asCents = (input, options = {}) => {
  const opts = { allowNegative: false, ...options };
  const value = Number(String(input).trim() || '');

  if (Number(value) === NaN) {
    return 0;
  }

  if (value.length === 0) {
    return 0;
  }

  if (!opts.allowNegative && Number(value) <= 0) {
    return 0;
  }

  return Math.round(Number(value).toFixed(2) * 100);
};

export const asCurrency = value => {
  if (Number(value) === NaN) {
    return 0;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(/\./.test(value) ? value : value / 100);
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

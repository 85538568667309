import { merge } from 'lodash';
import BaseTree from './BaseTree';

export default class CategoryTree extends BaseTree {
  settings() {
    const settings = {
      types: {
        default: { icon: 'glyphicon glyphicon-tags' },
        root: { icon: 'glyphicon glyphicon-book' },
        leaf: { icon: 'glyphicon glyphicon-tag' },
        branch: { icon: 'glyphicon glyphicon-tags' },
      },
    };

    return merge(super.settings(), settings);
  }
}

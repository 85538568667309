import { mergeWith, isArray, debounce } from 'lodash';
import $ from 'jquery';
import 'jstree';
import { el } from 'lib/helpers';

export default class BaseTree {
  constructor(element, source = []) {
    this.element = element instanceof HTMLElement ? element : el(element);
    this.$element = $(this.element);

    this.setSource(source);
    this.applySearch();
  }

  render(options = {}) {
    const settings = this.settings(options);

    if (this.tree) {
      this.tree.settings = settings;
      this.tree.refresh();

      return;
    }

    this.tree = this.$element
      .on('changed.jstree', this.onChange.bind(this))
      .on('ready.jstree', this.onRender.bind(this))
      .on('refresh.jstree', this.onRender.bind(this))
      .jstree(settings)
      .jstree(true);

    return this;
  }

  setSource(source) {
    this.source = source;

    return this;
  }

  settings() {
    return {
      core: {
        data: this.source,
        check_callback: this.canActionBePerformedOnNode.bind(this),
        expand_selected_on_load: true,
        multiple: true,
      },
      contextmenu: {
        select_node: false,
        items: this.contextMenuFor.bind(this),
      },
      types: {
        default: { icon: 'glyphicon glyphicon-folder-open' },
        root: { icon: 'glyphicon glyphicon-folder-open' },
        leaf: { icon: 'glyphicon glyphicon-file' },
        branch: { icon: 'glyphicon glyphicon-folder-open' },
      },
      plugins: ['types', 'changed', 'search'],
      search: {
        fuzzy: false,
        show_only_matches: true,
        show_only_matches_children: true,
        close_opened_onclear: false,
      },
    };
  }

  applySearch() {
    const input = el('.js-tree-search');
    const search = debounce(() => this.tree.search(input.value), 250, false);

    if (!input) {
      return;
    }

    input.addEventListener('keyup', search);
  }

  getParent(node) {
    return this.tree.get_parent(this.getNode(node));
  }

  getNode(node) {
    return this.tree.get_node(node);
  }

  shouldBeDisabled(node, action) {
    return !this.canActionBePerformedOnNode(
      action,
      node,
      this.getParent(node),
      ''
    );
  }

  onChange() {
    //
  }

  onRender() {
    //
  }

  contextMenuFor() {
    return false;
  }

  canActionBePerformedOnNode() {
    return false;
  }
}

export const fractional = weight => {
  const parts = [];
  const mass = parseFloat(weight);

  parts.push(Math.floor(mass));

  if (mass % 1 > 0) {
    parts.push(farey(mass % 1, 10).join('/'));
  }

  return parts.filter(n => n !== 0).join(' ');
};

export const unitOfMeasure = (value, count = 1, abbreviate = false) => {
  switch (value) {
    case 'lb':
    case 'lbs':
    case 'pound':
    case 'pounds':
      if (count < 2) {
        return abbreviate ? 'lb' : 'pound';
      }

      return abbreviate ? 'lbs' : 'pounds';
    case 'dry oz':
    case 'dry ounce':
    case 'oz':
      if (abbreviate) {
        return 'dry oz';
      }

      return [
        'dry ounce',
        count % 1 === 0 && Math.trunc(count) === 1 ? 's' : '',
      ].join('');
    case 'ea':
    case 'each':
    default:
      return abbreviate ? 'ea' : 'each';
  }
};

//
// Calculate the best rational approximation of a decimal.
// @link http://www.johndcook.com/blog/2010/10/20/best-rational-approximation/
//
export const farey = (x, n) => {
  let [a, b] = [0, 1];
  let [c, d] = [1, 1];
  let mediant;

  while (b <= n && d <= n) {
    mediant = (a + c) / (b + d);

    if (x === mediant) {
      if (b + d <= n) {
        return [a + c, b + d];
      }

      if (d > b) {
        return [c, d];
      }

      return [a, b];
    }

    if (x > mediant) {
      [a, b] = [a + c, b + d];

      continue;
    }

    [c, d] = [a + c, b + d];
  }

  if (b > n) {
    return [c, d];
  }

  return [a, b];
};

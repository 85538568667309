import { defaultsDeep } from 'lodash';
import { el, els } from 'lib/helpers';
import BaseTree from './BaseTree';

// tree for selecting a single store group to associate a store with
export default class AssociationTree extends BaseTree {
  constructor(element, source, formOptions = {}) {
    super(element, source);

    this.formOptions = defaultsDeep(formOptions, {
      formSelector: 'form',
      inputFormat: '{type}[]',
    });

    if (!/\{type\}/.test(this.formOptions.inputFormat)) {
      throw new Error("The [inputFormat] must contain the string: '{type}'");
    }

    this.form = el(this.formOptions.formSelector);
  }

  onRender() {
    const nodes = [];
    const inputs = els(this.querySelector(), this.form);

    [].forEach.call(inputs, input => {
      const type = /^(group|store)/.exec(input.getAttribute('name'))[1];

      nodes.push([type, input.value].join('-'));
    });

    this.tree.select_node(nodes);
  }

  onChange(e, data) {
    this.clearInputs();

    data.selected.forEach(id => {
      const [type, value] = id.split('-');

      this.addInput.call(this, type + 's', value);
    });
  }

  clearInputs() {
    const inputs = els(this.querySelector(), this.form);

    [].forEach.call(inputs, input => input.parentNode.removeChild(input));
  }

  addInput(type, id) {
    const input = document.createElement('input');

    input.setAttribute('name', this.fieldName(type));
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', id);

    this.form.appendChild(input);
  }

  querySelector() {
    return ['stores', 'groups']
      .map(type => this.fieldName(type))
      .map(name => 'input[name="' + name + '"]')
      .join(', ');
  }

  fieldName(type) {
    return this.formOptions.inputFormat.replace('{type}', type);
  }
}

import * as Ladda from 'ladda';

//
// For the reports page. This displays the ladda spinner on a button until
// the download response sets a new 'download-token' cookie value in the browser.
//
export default class ButtonDownload {
  constructor(button) {
    this.button = button;
    this.ladda = Ladda.create(this.button);

    this.tokenCookie = 'download-token';
    this.delay = 250;

    this.reset();
  }

  reset() {
    this.attempts = 150;
    this.token = Math.random().toString(36).slice(-5);

    if (this.timerId) {
      window.clearTimeout(this.timerId);

      this.timerId = null;
    }
  }

  start() {
    this.button.disabled = true;
    this.ladda.start();

    this.timerId = window.setInterval(() => this.check(), this.delay);
  }

  stop() {
    this.button.disabled = false;
    this.ladda.stop();

    this.reset();
  }

  check() {
    this.attempts--;

    if (this._tokenExists() || this.attempts <= 0) {
      this.stop();
    }
  }

  _tokenExists() {
    let parts = document.cookie.split(this.tokenCookie + '=');

    if (parts.length !== 2) {
      return false;
    }

    return parts.pop().split(';').shift() === this.token;
  }
}

import { range, flatten, uniqBy, sortBy } from 'lodash';
import { fractional, unitOfMeasure } from './utils';
import {
  UNIT_OF_MEASURE_POUND,
  UNIT_OF_MEASURE_DRY_OZ,
  UNIT_OF_MEASURE_EACH,
} from './constants';

const fractionalWeights = () => {
  const weightRanges = [
    range(1 / 4, 5, 1 / 4), // 1/4, 1/2, 3/4 ...
    range(1 / 3, 5, 1), // 1/3, 1 1/3, 2 1/3, ...
    range(2 / 3, 5, 1), // 2/3, 1 2/3, 2 2/3, ...
    range(5, 10 + 1 / 2, 1 / 2), // 5, 5 1/2, 6, 6 1/2
  ];

  return Array.from(new Set(flatten(weightRanges))).sort((a, b) =>
    a <= b ? -1 : 1
  );
};

const dryOunceQuantityChoices = () =>
  range(1, 51).map(amount => ({
    id: amount,
    calorie_modifier: null,
    srp_amount: 0,
    default: false,
    amount,
    value: [amount, unitOfMeasure(UNIT_OF_MEASURE_DRY_OZ, amount, true)].join(
      ' '
    ),
  }));

const eachQuantityChoices = () =>
  range(1, 51).map(amount => ({
    id: amount,
    calorie_modifier: null,
    srp_amount: 0,
    default: false,
    amount,
    value: amount,
  }));

const poundQuantityChoices = (acceptsFractionalQuantities = true) => {
  let weights = range(1, 51, 1);

  if (acceptsFractionalQuantities) {
    weights.push(...fractionalWeights());
  }

  weights = Array.from(new Set(weights)).sort((a, b) => (a <= b ? -1 : 1));

  return weights.map(amount => ({
    id: amount,
    calorie_modifier: null,
    srp_amount: 0,
    default: false,
    amount,
    value: [
      fractional(amount.toFixed(2)),
      unitOfMeasure(UNIT_OF_MEASURE_POUND, amount, true),
    ].join(' '),
  }));
};

export const quantitiesForProduct = product => {
  const minimumQuantity = Math.max(product.minimum_quantity ?? -1, 0);
  let choices;

  switch (product.unit_of_measure || UNIT_OF_MEASURE_POUND) {
    case UNIT_OF_MEASURE_DRY_OZ:
      choices = dryOunceQuantityChoices();
      break;
    case UNIT_OF_MEASURE_EACH:
      choices = eachQuantityChoices();
      break;
    case UNIT_OF_MEASURE_POUND:
    default:
      choices = poundQuantityChoices(product.accepts_fractional_quantities);
  }

  // reject quantities under the minimum
  // @see https://trello.com/c/vIaf53UP
  choices = choices.filter(c => c.amount >= minimumQuantity);

  // make '1' or the first choice the default
  const defaultChoice = choices.find(c => c.amount === 1) || choices?.[0];

  if (defaultChoice) {
    defaultChoice.default = true;
  }

  return choices;
};

/**
 * Gather a unique list of all quantities. This is used by the 'minimum quantity'.
 *
 * @return {object[]}
 */
export const allQuantities = () =>
  sortBy(
    uniqBy(
      [
        ...eachQuantityChoices(),
        ...dryOunceQuantityChoices(),
        ...poundQuantityChoices(true),
      ],
      'amount'
    ),
    'amount'
  );

import $ from 'jquery';
import moment from 'moment';
import 'pickadate/lib/picker.date';
import 'pickadate/lib/picker.time';
import { el } from 'lib/helpers';

/**
 * This class manages a pair of pickadate date & time pickers that allow the user
 * to select a timestamp for use throughout the admin site.
 *
 * This works in hand with the resources/views/admin/_date_time_picker.blade.php partial
 *
 *
 */
export default class {
  constructor(which) {
    this.which = which;
    this.el = el(this.identifyingClass(which));

    this.initializePickers(this.which);
  }

  /**
   * Configure the pickadate date & time pickers for the start and end of the 'active'
   * window for the alert.
   */
  initializePickers() {
    const pickadateChangeOptions = {
      set: this.setRawTimestamp.bind(this),
    };

    // set the pickadate fields for the date/time picker which are displayed to the
    // user, using the hidden source input value as the timstamp to parse.
    const timestamp = moment.utc(this.input().value || undefined).local();

    this.datePicker().value = timestamp.format('MMMM D, YYYY');
    this.timePicker().value = timestamp.format('h:mm A');

    // configure the date picker
    $(this.datePicker()).pickadate({
      format: 'mmmm d, yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenName: true,
    });

    // configure the timepicker
    $(this.timePicker()).pickatime({
      formatSubmit: 'HH:i',
      hiddenName: true,
      interval: 5,
    });

    // configure the onchange events for the date & time picker to
    // update underlying field sent to the backend
    $(this.datePicker()).pickadate('on', pickadateChangeOptions);
    $(this.timePicker()).pickatime('on', pickadateChangeOptions);
  }

  /**
   * Whenever the pickadate pickers update, this function will be called to update
   * the hidden input field the date/time pickers are associated with
   */
  setRawTimestamp() {
    // get the current string value for the date and time picker
    const date = $(this.datePicker()).pickadate('get');
    const time = $(this.timePicker()).pickatime('get');

    // convert the date to a moment.js instance
    const timestamp = (date ? moment(date, 'MMMM D, YYYY') : moment()).startOf(
      'day'
    );

    if (time) {
      // since a time is available on the timepicker instance, apply it to the
      // moment.js instance
      const [hours, minutes] = moment(time, 'h:mm A')
        .format('HH:mm')
        .split(':');

      timestamp.add({ hours, minutes });
    }

    timestamp.utc();

    // set the formatted value the backend expects as the value of the hidden input
    this.input().value = timestamp.format('YYYY-MM-DD HH:mm');
  }

  identifyingClass(suffix) {
    return `.js-datepicker--${suffix}`;
  }

  /**
   * querySelector helper that looks within the root element of this date/time
   * picker instance.
   */
  find(what) {
    return this.el.querySelector(what);
  }

  /**
   * Search for an element with a class that uses the `.js-datepicker--` prefix,
   * found within the root element of this date/time picker instance.
   */
  fetch(what) {
    return this.find(this.identifyingClass(what));
  }

  input() {
    return this.fetch('field');
  }

  datePicker() {
    return this.fetch('date');
  }

  timePicker() {
    return this.fetch('time');
  }
}
